import React, {useState} from 'react'
import { Form, Button, Alert } from 'react-bootstrap'


export default function Inschrijven( {tentoonstelling, inschrijving, setInschrijving, setStap} ) {

    const [validated, setValidated] = useState(false);

    const opties = JSON.parse(tentoonstelling.vereniging.opties)

    function handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      if (form.checkValidity() === true) {
        setStap(2)
      }

      setValidated(true);
    }

    function isActiveTentoonstelling(){
      let einddatum = new Date(tentoonstelling.eindDatum)
      einddatum = new Date(einddatum.setHours(23, 59, 59))
      const today = new Date()
      if(einddatum >= today){
        return true
      }
      return false
    }

    return (

        <>
          {!isActiveTentoonstelling() &&
            <Alert variant="warning">
              Let op, de sluitingsdatum van deze tentoonstelling is verstreken.
            </Alert>
          }
          {isActiveTentoonstelling() &&
          <>
          <h4 className="mb-3">Contactgegevens</h4>

          <Form noValidate className="needs-validation" validated={validated} onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-12">

                <Form.Group controlId="formGridNaam">
                  <Form.Label>Naam</Form.Label>
                  <Form.Control type="text" required
                    value={inschrijving.naam}
                    onChange={(e) => setInschrijving({...inschrijving, naam:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    We willen graag weten wie je bent.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-6">

                <Form.Group controlId="formGridTelefoonnummer">
                  <Form.Label>Telefoonnummer</Form.Label>
                  <Form.Control type="text" required
                    value={inschrijving.telefoon}
                    onChange={(e) => setInschrijving({...inschrijving, telefoon:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    We willen je kunnen bellen als dat nodig is.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-6">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>E-mailadres</Form.Label>
                  <Form.Control type="email" required
                    value={inschrijving.email}
                    onChange={(e) => setInschrijving({...inschrijving, email:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    We willen je een e-mail kunnen sturen ter bevestiging.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-12">

                <Form.Group controlId="formGridAdres">
                  <Form.Label>Adres</Form.Label>
                  <Form.Control type="text" required
                    value={inschrijving.adres}
                    onChange={(e) => setInschrijving({...inschrijving, adres:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    Vul je adresgegevens in.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-5">

                <Form.Group controlId="formGridPostcode">
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control type="text" required
                    value={inschrijving.postcode}
                    onChange={(e) => setInschrijving({...inschrijving, postcode:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    Vul je postcode in.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-7">

                <Form.Group controlId="formGridWoonplaats">
                  <Form.Label>Woonplaats</Form.Label>
                  <Form.Control type="text" required
                    value={inschrijving.woonplaats}
                    onChange={(e) => setInschrijving({...inschrijving, woonplaats:e.target.value})}
                  />
                  <Form.Control.Feedback type="invalid">
                    Vul je woonplaats in.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>

              <div className="col-12">

                {opties.jeugd &&
                  <Form.Group controlId="formGridGeboortedatum">
                    <Form.Label>Geboortedatum</Form.Label>
                    <Form.Control type="date" required
                      value={inschrijving.geboortedatum}
                      onChange={(e) => setInschrijving({...inschrijving, geboortedatum:e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul je geboortedatum in.
                    </Form.Control.Feedback>
                  </Form.Group>
                }

              </div>

              <hr className="my-4" />

              <div className="col-md-5">
                {opties.velden.lidafdeling &&
                  <Form.Group controlId="formGridLidafdeling">
                    <Form.Label>Lid afdeling</Form.Label>
                    <Form.Control type="text" required
                      value={inschrijving.lidafdeling}
                      onChange={(e) => setInschrijving({...inschrijving, lidafdeling:e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul je de afdeling in waarvin je lid bent.
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </div>

              <div className="col-md-7">
                {opties.velden.kweeknummer &&
                  <Form.Group controlId="formGridKweeknummer">
                    <Form.Label>Kweeknummer</Form.Label>
                    <Form.Control type="text" required
                      value={inschrijving.kweeknummer}
                      onChange={(e) => setInschrijving({...inschrijving, kweeknummer:e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul je kweeknummer in.
                    </Form.Control.Feedback>
                  </Form.Group>
                }

              </div>

              <div className="col-md-5">
                {opties.velden.codevereniging &&
                  <Form.Group controlId="formGridCodevereniging">
                    <Form.Label>Code vereniging</Form.Label>
                    <Form.Control type="text" required
                      value={inschrijving.codevereniging}
                      onChange={(e) => setInschrijving({...inschrijving, codevereniging:e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul de code van je vereniging in.
                    </Form.Control.Feedback>
                  </Form.Group>
                }

              </div>

              <div className="col-md-7">
                {opties.velden.naamvereniging &&
                  <Form.Group controlId="formGridNaamvereniging">
                    <Form.Label>Naam vereniging</Form.Label>
                    <Form.Control type="text" required
                      value={inschrijving.naamvereniging}
                      onChange={(e) => setInschrijving({...inschrijving, naamvereniging:e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul de naam van je vereniging in.
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </div>

            </div>

            <hr className="my-4" />

            <Form.Control type="hidden" required value={tentoonstelling.vereniging.id} />

            <Button className="w-100" variant="primary" size="lg" type="submit">
              Naar stap 2
            </Button>
          </Form>
          </>
        }

        </>
      )

}
