import React from 'react'

export default function Vereniging( {tentoonstelling} ) {

    return (
      <>
        <h6 className="mb-3">Heb je vragen, neem contact op:</h6>
        <p>{tentoonstelling.vereniging.ttsecretaris}<br />
        {tentoonstelling.vereniging.adres}<br />
        {tentoonstelling.vereniging.postcode}  {tentoonstelling.vereniging.woonplaats}</p>
        <p>{tentoonstelling.vereniging.telefoon}<br />
        <a href={"mailto:"+tentoonstelling.vereniging.email}>{tentoonstelling.vereniging.email}</a></p>
        {tentoonstelling.vereniging.logo && <p>
          <img
            src={process.env.REACT_APP_URL+"/logo/"+tentoonstelling.vereniging.logo}
            className="mw-100"
            alt={tentoonstelling.vereniging.naam} /></p>}
      </>
    )
}
