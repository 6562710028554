import React from 'react'


export default function PrijsRegels( {prijsregels} ) {

    function formatCurrency(num) {
       return '€ ' + parseFloat(num).toFixed(2).replace(".", ",")
    }

    const prijsregelElements = prijsregels.map(prijsregel => {
      if(prijsregel.prijs !== 0 && prijsregel.prijs !== ''){
        return (
          <div className="row g-3 mb-3 border-top border-2" key={prijsregel.id}>
            <div className="col-1">
              <p>{prijsregel.aantal > 1 && prijsregel.aantal+" x"}</p>
            </div>
            <div className="col-6">
              <p>{prijsregel.naam}</p>
            </div>
            <div className="col-5 text-end">
              <p>{formatCurrency(prijsregel.prijs)}</p>
            </div>
          </div>
        )
      }else{
        return ''
      }
    })

    return (
      <>

        {prijsregelElements}

      </>
    )
}
