import React from 'react'

export default function Header( {tentoonstelling, stap} ) {

    return (

      <div className="py-5 text-center">
        <h2>Inschrijven {tentoonstelling.vereniging.naam} {tentoonstelling.jaar}</h2>
        <p><em><strong>Sluitingsdatum:</strong> {new Date(tentoonstelling.sluitingsDatum).toLocaleDateString()}</em></p>

        {stap === 1 && <p className="lead">Schrijf je in voor de vogeltentoonstelling van {tentoonstelling.vereniging.naam}. Begin hieronder met het invullen van je contactgegevens.</p>}
        {stap === 2 && <p className="lead">Zoek vogels op klassenummer of kleurslag en voeg deze toe.</p>}
        {stap === 3 && <p className="lead">Maak je keuzes en bevestig je inschrijving.</p>}

      </div>

    )
}
