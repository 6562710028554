import React from 'react'

export default function Footer() {

    return (
          <footer className="my-5 pt-5 text-muted text-center text-small">
            <p className="mb-1">&copy; 2021 New Lime Media</p>
          </footer>
    )
}
