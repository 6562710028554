import React from 'react'
import { Button } from 'react-bootstrap'

export default function VogelsSearchList( {vogelsSearch, handleAddVogel} ) {

    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    const vogelsElements = vogelsSearch.map(vogel => {
        return (
            <li className="list-group-item" key={vogel.id}>
              {formatNumber(vogel.klasse)} - {vogel.kleurslag} <Button size="sm" className="float-end" onClick={(e) => handleAddVogel(vogel)}>Toevoegen</Button>
            </li>
        )
    })

    return (
      <>
        <ul className="list-group mb-3">
          {vogelsElements}
        </ul>
      </>
    )
}
