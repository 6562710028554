import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { Form, Button, Alert } from 'react-bootstrap'
import PrijsRegels from './PrijsRegels'
import KooiHuur from './KooiHuur'
import Sponsoring from './Sponsoring'
import Derby from './Derby'
import Prijzen from './Prijzen'


export default function ExtraOpties( {tentoonstelling, jeugd, vogels, setVogels, prijsregels, setPrijsregels, inschrijving, setInschrijving, kooihuur, setKooihuur, stap} ) {

    function formatCurrency(num) {
       return '€ ' + num.toFixed(2).replace(".", ",")
    }

    const [validated, setValidated] = useState(false);
    const [result, setResult] = useState({'result':'', 'message': ''});

    const opties = JSON.parse(tentoonstelling.vereniging.opties)
    const prijzen = JSON.parse(tentoonstelling.vereniging.prijzen)

    function handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      if (form.checkValidity() === true) {
        const sendInschrijving = {...inschrijving, totaal:prijsregelsTotal()}
        axios.post(process.env.REACT_APP_URL+'/api/post-inschrijving.php', { tentoonstelling: tentoonstelling.id, inschrijving: sendInschrijving, vogels: vogels, prijsregels: prijsregels })
          .then(res => {
            if(res.data !== ''){
              setResult(res.data)
              if(res.data.status === 'success'){
                console.log('succes')
                //clear states
                setInschrijving({
                  'naam': '',
                  'telefoon': '',
                  'email': '',
                  'adres': '',
                  'postcode': '',
                  'woonplaats': '',
                  'geboortedatum': '',
                  'lidafdeling': '',
                  'kweeknummer': '',
                  'codevereniging': '',
                  'naamvereniging': '',
                  'prijs': '',
                  'akkoord': false,
                  'totaal': 0,
                  'aantal': 0,
                  'aantalVogels': 0,
                  'verzekeringVogel': 0,
                  'verzekeringKooi': 0
                })
                setVogels([])
                setPrijsregels([{"id":uuidv4(), "naam": "inschrijvingen", "aantal": 1, "prijs": 0},{"id":uuidv4(), "naam": "korting", "aantal":1, "prijs": 0}])
              }
            }
          })
      }

      setValidated(true);
    }

    function prijsregelsTotal(){
        let total = 0

        prijsregels.forEach(prijsregel => {
          if(prijsregel.prijs !== ''){
            total += parseFloat(prijsregel.prijs)
          }
        })

        return total
    }


    return (
      <>
        <Form noValidate className="needs-validation" validated={validated} onSubmit={handleSubmit}>
          {result.status !== 'success' && <div>
            <h4 className="mb-3 pb-3">Extra opties</h4>
              {opties.kooihuur !== undefined && opties.kooihuur.active && <KooiHuur inschrijving={inschrijving} opties={opties} prijsregels={prijsregels} setPrijsregels={setPrijsregels} />}
              {opties.sponsoring !== undefined && opties.sponsoring.active && <Sponsoring inschrijving={inschrijving} opties={opties} prijsregels={prijsregels} setPrijsregels={setPrijsregels} />}
              {opties.derby !== undefined && opties.derby.active && <Derby inschrijving={inschrijving} opties={opties} prijsregels={prijsregels} setPrijsregels={setPrijsregels} />}
              {prijzen.length !== 0 && <Prijzen inschrijving={inschrijving} setInschrijving={setInschrijving} prijzen={prijzen} />}

            <hr />

            <h4 className="mb-3 pb-3">Overzicht</h4>
            <div className="container mb-5 mt-5">
              <PrijsRegels prijsregels={prijsregels}/>
              <div className="row g-3 mb-3 border-top border-3 border-primary">
                <div className="col-6">
                  <h5>Totaal</h5>
                </div>
                <div className="col-6 text-end">
                  <h5>{formatCurrency(prijsregelsTotal())}</h5>
                </div>
              </div>
            </div>
            <hr />

            <Form.Group controlId="formGridAkkoord">
                <Form.Check
                    required
                    inline
                    label={"Ik ga akkoord met mijn inschrijving en zal het verschuldigde bedrag van "+formatCurrency(prijsregelsTotal())+" betalen. De ingevulde gegevens heb ik gecontroleerd en zijn correct."}
                    name="group1"
                    type="checkbox"
                    id="akkoord"
                    className="mb-3"
                    checked={inschrijving.akkoord}
                    onChange={(e) => setInschrijving({...inschrijving, akkoord:e.target.checked})}
                />
            </Form.Group>
          </div>}

          {result.status === 'error' && <Alert variant="danger">
            {result.message}
          </Alert>}

          {result.status === 'success' && <Alert variant="success">
            {result.message}
          </Alert>}

          <Button className="w-100" variant="primary" size="lg" type="submit" disabled={result.status === 'success' }>
            Definitief inschrijven
          </Button>
        </Form>
      </>
    )
}
