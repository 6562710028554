import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function TentoonstellingenList() {

    const [tentoonstellingen,setTentoonstellingen] = useState([]);

    const getTentoonstellingen = () => {
      axios.get(process.env.REACT_APP_URL+'/api/list-tentoonstellingen.php')
        .then(res => {
          setTentoonstellingen(res.data)
        })
    }

    useEffect(() => {
      getTentoonstellingen()
    }, [])

    const tentoonstellingenElements = tentoonstellingen.map(tentoonstelling => {
        return (
            <li className="list-group-item" key={tentoonstelling.id}>
              <Link to={tentoonstelling.slug} title={tentoonstelling.naam}>{tentoonstelling.naam}</Link>
            </li>
        )
    })

    return (
      <>
        <div className="py-5">
            <h2>Inschrijven</h2>
            <p className="lead">Voor welk van de volgende tentoonstellingen wil je je inschrijven?</p>
        </div>

        <div className="row g-5">
          <div className="col-md-8">
            <ul className="list-group">
              {tentoonstellingenElements}
            </ul>
          </div>
        </div>
      </>
    )
}
