import React, {useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Form, FormControl, Row, Col } from 'react-bootstrap'

export default function Sponsoring( {inschrijving, opties, prijsregels, setPrijsregels} ) {

    useEffect(() => {
        const exist = prijsregels.filter(r => r.naam === 'Sponsoring')
        if(exist.length === 0){
          setPrijsregels([...prijsregels, {"id":uuidv4(), "naam": "Sponsoring", "aantal":1, "prijs": parseFloat(opties.sponsoring.prijs)}])
        }
    }, [prijsregels, setPrijsregels, opties.sponsoring.prijs])


    function handleChangeSponsoring(bedrag){
      const exist = prijsregels.filter(r => r.naam === 'Sponsoring')
      if(exist.length !== 0){
          const newPrijsregels = [...prijsregels]
          const index = newPrijsregels.findIndex(r => r.id === exist[0].id)
          newPrijsregels[index] = {...exist[0], ...bedrag}
          setPrijsregels(newPrijsregels)
        }
    }



    return (

      <>
        <h6>Sponsoring</h6>

          {prijsregels.filter(r => r.naam === 'Sponsoring')[0] && <Form.Group as={Row} className="mb-3" controlId="bedragSponsoring">
              <Form.Label column sm={{ span: 3, offset: 1 }}>
                Bedrag
              </Form.Label>
              <Col sm="8">
                <FormControl type="number" required min="0" step="0.01"
                value={prijsregels.filter(r => r.naam === 'Sponsoring')[0].prijs}
                onChange={(e)=> handleChangeSponsoring({'prijs': e.target.value})} />
                <Form.Control.Feedback type="invalid">
                  Vul het een sponsorbedrag in, wil je niet sponsoren vul dan 0 in.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>}
      </>

    )
}
