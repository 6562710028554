import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Form, Row, Col } from 'react-bootstrap'

export default function KooiHuur( {inschrijving, opties, prijsregels, setPrijsregels} ) {

    function handleChangeKooihuur(active){
        const exist = prijsregels.filter(r => r.naam === 'Kooihuur')
        if(active){
          if(exist.length === 0){
            setPrijsregels([...prijsregels, {"id":uuidv4(), "naam": "Kooihuur", "aantal":parseInt(inschrijving.aantalVogels), "prijs": (inschrijving.aantalVogels * opties.kooihuur.prijs)}])
          }
        }else{
          if(exist.length !== 0){
            setPrijsregels(prijsregels.filter(prijsregel => prijsregel.id !== exist[0].id))
          }
        }
    }

    function handleChangeKooihuurAantal(aantal){
        const exist = prijsregels.filter(r => r.naam === 'Kooihuur')
        if(exist.length !== 0){
            const newPrijsregels = [...prijsregels]
            const index = newPrijsregels.findIndex(r => r.id === exist[0].id)
            newPrijsregels[index] = {...exist[0], ...{'aantal': aantal, "prijs": (aantal * opties.kooihuur.prijs)}}
            setPrijsregels(newPrijsregels)
        }
    }

    return (

      <>
        <h6>Kooihuur</h6>
          <Form.Group as={Col} sm={{ span: 11, offset: 1 }} controlId="formGridKooihuur">
              <Form.Check
                  inline
                  label={"Ik wil kooien huren"}
                  name="kooihuur"
                  type="checkbox"
                  id="kooihuur"
                  className="mb-3"
                  checked={prijsregels.filter(r => r.naam === 'Kooihuur')[0] !== undefined}
                  onChange={(e) => handleChangeKooihuur(e.target.checked)}
              />
          </Form.Group>
          {prijsregels.filter(r => r.naam === 'Kooihuur')[0] && prijsregels.filter(r => r.naam === 'Kooihuur')[0].length !== 0 && <Form.Group as={Row} className="mb-3" controlId="aantalKooihuur">
              <Form.Label column sm={{ span: 3, offset: 1 }}>
                Aantal kooien
              </Form.Label>
              <Col sm="8">
                <Form.Control type="number" required min="1" max={inschrijving.aantalVogels}
                value={prijsregels.filter(r => r.naam === 'Kooihuur')[0].aantal}
                onChange={(e)=> handleChangeKooihuurAantal(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Vul het aantal kooien in dat je wilt huren, het aantal kooien kan niet meer zijn dan het aantal in te schrijven vogels.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>}
      </>

    )
}
