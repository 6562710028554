import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import Header from './Header'
import Stappen from './Stappen'
import Vereniging from './Vereniging'
import Inschrijven from './Inschrijven'
import Vogels from './Vogels'
import ExtraOpties from './ExtraOpties'

export default function Stap( props ) {
    const { match: { params } } = props

    const [stap, setStap] = useState(1)

    const [tentoonstelling, setTentoonstelling] = useState()

    const [vogels, setVogels] = useState([])

    const [inschrijving, setInschrijving] = useState({
      'naam': '',
      'telefoon': '',
      'email': '',
      'adres': '',
      'postcode': '',
      'woonplaats': '',
      'geboortedatum': '',
      'lidafdeling': '',
      'kweeknummer': '',
      'codevereniging': '',
      'naamvereniging': '',
      'prijs': '',
      'akkoord': false,
      'totaal': 0,
      'aantal': 0,
      'aantalVogels': 0,
      'verzekeringVogel': 0,
      'verzekeringKooi': 0
    });

    const [prijsregels, setPrijsregels] = useState([{"id":uuidv4(), "naam": "inschrijvingen", "aantal": 1, "prijs": 0},{"id":uuidv4(), "naam": "korting", "aantal":1, "prijs": 0}])

    const LOCAL_STORAGE_KEY_INSCHRIJVING = 'vraagprogramma.inschrijving'
    const LOCAL_STORAGE_KEY_VOGELS = 'vraagprogramma.vogels'

    useEffect(() => {
      const inschrijvingJSON = localStorage.getItem(LOCAL_STORAGE_KEY_INSCHRIJVING)
      if (inschrijvingJSON != null) setInschrijving(JSON.parse(inschrijvingJSON))
    }, [])

    useEffect(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY_INSCHRIJVING, JSON.stringify(inschrijving))
    }, [inschrijving])

    useEffect(() => {
      const vogelsJSON = localStorage.getItem(LOCAL_STORAGE_KEY_VOGELS)
      if (vogelsJSON != null) setVogels(JSON.parse(vogelsJSON))
    }, [])

    useEffect(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY_VOGELS, JSON.stringify(vogels))
    }, [vogels])

    let history = useHistory();

    const getTentoonstelling = useCallback(() => {
      axios.get(process.env.REACT_APP_URL+'/api/get-tentoonstelling.php',{ params })
        .then(res => {
          if(res.data !== ''){
            setTentoonstelling(res.data)
          }else{
            history.push('/')
          }
        })
    }, [params, setTentoonstelling, history])

    useEffect(() => {
      getTentoonstelling()
    }, [getTentoonstelling])

    function getAge(DOB) {
        let today = new Date();
        let birthDate = new Date(DOB);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
      <>
        {!tentoonstelling && <h3>Geen tentoonstelling gevonden</h3>}
        {tentoonstelling && <Header tentoonstelling={tentoonstelling} stap={stap} />}
        <div className="row g-5">
          <div className="col-md-5 col-lg-4 order-md-last">

              <Stappen stap={stap} setStap={setStap} />

              {tentoonstelling && <Vereniging tentoonstelling={tentoonstelling} />}

          </div>
          <div className="col-md-7 col-lg-8">

            {tentoonstelling && stap === 1 && <Inschrijven
              tentoonstelling={tentoonstelling}
              inschrijving={inschrijving}
              setInschrijving={setInschrijving}
              setStap={setStap}
            />}

            {tentoonstelling && stap === 2 && <Vogels
              tentoonstelling={tentoonstelling}
              inschrijving={inschrijving}
              setInschrijving={setInschrijving}
              jeugd={getAge(inschrijving.geboortedatum) <= 16}
              vogels={vogels}
              setVogels={setVogels}
              prijsregels={prijsregels}
              setPrijsregels={setPrijsregels}
              setStap={setStap}
            />}

            {tentoonstelling && stap === 3 && <ExtraOpties
              tentoonstelling={tentoonstelling}
              jeugd={getAge(inschrijving.geboortedatum) <= 16}
              vogels={vogels}
              setVogels={setVogels}
              prijsregels={prijsregels}
              setPrijsregels={setPrijsregels}
              inschrijving={inschrijving}
              setInschrijving={setInschrijving}
              stap={stap}
            />}

          </div>
        </div>
      </>
    )
}
