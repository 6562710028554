import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { FormControl, InputGroup } from 'react-bootstrap'
import VogelsSearchList from './VogelsSearchList'
import VogelsList from './VogelsList'

export default function Vogels( {tentoonstelling, inschrijving, setInschrijving, jeugd, vogels, setVogels, prijsregels, setPrijsregels, setStap} ) {

    const opties = JSON.parse(tentoonstelling.vereniging.opties)
    const kosten = JSON.parse(tentoonstelling.vereniging.kosten)

    const [search,setSearch] = useState({'query':'', 'hoofdstukken':JSON.stringify(opties.hoofdstukken) })
    const [vogelsSearch,setVogelsSearch] = useState([])

    const getVogelsSearch = useCallback(() => {
      axios.get(process.env.REACT_APP_URL+'/api/find-vogels.php',{ params: { search: search.query, hoofdstukken: search.hoofdstukken } })
        .then(res => {
          setVogelsSearch(res.data)
        })
    }, [search])

    useEffect(() => {
      getVogelsSearch()
    }, [search, getVogelsSearch])

    function handleAddVogel(vogel){
      setSearch({...search, query:''})
      setVogels([...vogels, {'id': uuidv4(),'soort':kosten[0].naam, 'type':0, 'typeAantal':1, 'aantal': 1, 'totaal':0, 'verzekeringVogel':'', 'verzekeringKooi': '', 'vogel': vogel}])
    }

      useEffect(() => {

        let aantal = 0
        let aantalVogels = 0
        let totaal = 0
        let verzekeringVogel = 0
        let verzekeringKooi = 0
        const kosten = JSON.parse(tentoonstelling.vereniging.kosten)

        vogels.forEach(vogel => {
          aantal += parseInt(vogel.aantal)
          aantalVogels += kosten[vogel.type].aantal*vogel.aantal
          if(jeugd){
            totaal += kosten[vogel.type].kostenJeugd*vogel.aantal
          }else{
            totaal += kosten[vogel.type].kosten*vogel.aantal
          }
          verzekeringVogel += vogel.verzekeringVogel*vogel.aantal
          verzekeringKooi += vogel.verzekeringKooi*vogel.aantal
        })
        setInschrijving(inschrijving => ({...inschrijving, totaal:totaal, aantal:aantal, aantalVogels:aantalVogels, verzekeringVogel:verzekeringVogel, verzekeringKooi:verzekeringKooi}))
    }, [vogels, jeugd, setInschrijving, tentoonstelling.vereniging.kosten])

    return (
      <>
        <h4 className="mb-3">Vogels toevoegen</h4>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Vogel zoeken"
            aria-label="Vogel zoeken"
            aria-describedby="basic-addon2"
            value={search.query}
            onChange={(e) => setSearch({...search, query:e.target.value.split('.').join('')})}
          />
        </InputGroup>
        {vogelsSearch && <VogelsSearchList vogelsSearch={vogelsSearch} handleAddVogel={handleAddVogel} />}
        {vogels && <VogelsList
          tentoonstelling={tentoonstelling}
          inschrijving={inschrijving}
          setInschrijving={setInschrijving}
          jeugd={jeugd}
          vogels={vogels}
          setVogels={setVogels}
          prijsregels={prijsregels}
          setPrijsregels={setPrijsregels}
          setStap={setStap} />}
      </>
    )
}
