import React from 'react'
import { Button } from 'react-bootstrap'

export default function Stappen( {stap, setStap} ) {

    return (
      <>
        <h4 className="d-flex justify-content-between align-items-center mb-3">
          <span>Stappen</span>
        </h4>
        <ul className="list-group mb-5">
          <li className={"list-group-item d-flex justify-content-between lh-sm "+ (stap >= 1 ? 'bg-light': '')}>
            <div>
              <h6 className={"my-0 "+ (stap === 1 ? 'text-primary': '')}>Stap 1</h6>
              <small className="text-muted">Contactgegevens invullen</small>
            </div>
            {stap > 1 && <Button variant="link" className="float-end" onClick={(e) => setStap(1)} >Terug</Button>}
          </li>
          <li className={"list-group-item d-flex justify-content-between lh-sm "+ (stap >= 2 ? 'bg-light': '')}>
            <div>
              <h6 className={"my-0 "+ (stap === 2 ? 'text-primary': '')}>Stap 2</h6>
              <small className="text-muted">Vogels toevoegen</small>
            </div>
            {stap > 2 && <Button variant="link" className="float-end" onClick={(e) => setStap(2)} >Terug</Button>}
          </li>
          <li className={"list-group-item d-flex justify-content-between lh-sm "+ (stap >= 3 ? 'bg-light': '')}>
            <div>
              <h6 className={"my-0 "+ (stap === 3 ? 'text-primary': '')}>Stap 3</h6>
              <small className="text-muted">Extra opties & overzicht</small>
            </div>
          </li>
        </ul>
      </>
    )

}
