import React, {useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Form, FormControl, Row, Col } from 'react-bootstrap'

export default function Derby( {inschrijving, opties, prijsregels, setPrijsregels} ) {

    useEffect(() => {
        const exist = prijsregels.filter(r => r.naam === 'Derby')
        if(exist.length === 0){
          setPrijsregels([...prijsregels, {"id":uuidv4(), "naam": "Derby", "aantal":0, "prijs": 0}])
        }
    }, [prijsregels, setPrijsregels, opties.derby.prijs])


    function handleChangeDerby(aantal){
      const exist = prijsregels.filter(r => r.naam === 'Derby')
      if(exist.length !== 0){
          const newPrijsregels = [...prijsregels]
          const index = newPrijsregels.findIndex(r => r.id === exist[0].id)
          newPrijsregels[index] = {...exist[0], ...{'aantal': parseInt(aantal), 'prijs': (aantal * parseFloat(opties.derby.prijs))}}
          setPrijsregels(newPrijsregels)
        }
    }



    return (

      <>
        <h6>Derby</h6>

          {prijsregels.filter(r => r.naam === 'Derby')[0] && <Form.Group as={Row} className="mb-3" controlId="aantalDerby">
              <Form.Label column sm={{ span: 3, offset: 1 }}>
                Aantal vogels
              </Form.Label>
              <Col sm="8">
                <FormControl type="number" required min="0"
                value={prijsregels.filter(r => r.naam === 'Derby')[0].aantal}
                onChange={(e)=> handleChangeDerby(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Vul het aantal vogels in waarmee je mee wilt doen voor de derby.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>}
      </>

    )
}
