import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, Form, FormControl } from 'react-bootstrap'
import { IoDuplicateOutline, IoTrashOutline } from 'react-icons/io5';


export default function VogelsList( {tentoonstelling, inschrijving, setInschrijving, jeugd, vogels, setVogels, prijsregels, setPrijsregels, setStap} ) {

    const [validated, setValidated] = useState(false);

    const kosten = JSON.parse(tentoonstelling.vereniging.kosten)
    const opties = JSON.parse(tentoonstelling.vereniging.opties)

    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    function formatCurrency(num) {
       return '€ ' + num.toFixed(2).replace(".", ",")
    }

    function handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      if (form.checkValidity() === true) {
        const newPrijsregels = prijsregels
        newPrijsregels[0].naam = "Inschrijvingen ("+inschrijving.aantalVogels+" vogels)"
        newPrijsregels[0].aantal = inschrijving.aantal
        newPrijsregels[0].prijs = inschrijving.totaal
        if(opties.korting && opties.korting.type === "nthdiscount"){
          console.log(inschrijving.totaal / inschrijving.aantalVogels, inschrijving.aantalVogels, opties.korting.n, (inschrijving.aantalVogels / opties.korting.n), parseInt(inschrijving.aantalVogels / opties.korting.n))
          const kortingBedrag = -( inschrijving.totaal / inschrijving.aantalVogels ) * parseInt(inschrijving.aantalVogels / opties.korting.n)
          newPrijsregels[1].naam = opties.korting.naam
          newPrijsregels[1].prijs = kortingBedrag
        }
        opties.vasteKosten.forEach((kosten, index) => {
            if(kosten.prijs !== 0){
              const exist = newPrijsregels.filter(prijsregel => prijsregel.naam === kosten.naam)
              if(exist.length === 0){
                newPrijsregels.push({"id":uuidv4(), "naam": kosten.naam,"aantal":1, "prijs": kosten.prijs})
              }
            }
        })
        setPrijsregels((prijsregels) => (newPrijsregels))
        setInschrijving({...inschrijving, akkoord:false})

        setStap(3)
      }

      setValidated(true);
    }

    function handleDeleteVogel(id){
      setVogels(vogels.filter(vogel => vogel.id !== id))
    }

    function handleDuplicateVogel(id){
      const newVogel = vogels.filter(vogel => vogel.id === id)[0]
      setVogels([...vogels, {'id': uuidv4(), 'soort': newVogel.soort, 'type': newVogel.type, 'typeAantal': newVogel.typeAantal, 'aantal': newVogel.aantal, 'totaal': newVogel.totaal, 'verzekeringVogel':newVogel.verzekeringVogel, 'verzekeringKooi': newVogel.verzekeringKooi, 'vogel': newVogel.vogel}])
    }

    function handleChangeVogel(id, change){
      const newVogels = [...vogels]
      const index = newVogels.findIndex(r => r.id === id)
      const changeVogel = vogels.filter(vogel => vogel.id === id)[0]
      let totaal = 0;
      newVogels[index] = {...changeVogel, ...change}

      if(jeugd){
        totaal = kosten[newVogels[index].type].kostenJeugd*newVogels[index].aantal
      }else{
        totaal = kosten[newVogels[index].type].kosten*newVogels[index].aantal
      }

      newVogels[index] = {...newVogels[index], ...{'totaal': totaal, 'typeAantal': kosten[newVogels[index].type].aantal*newVogels[index].aantal}}

      setVogels(newVogels)
    }

    const typeElements = kosten.map((kosten, index) => {
        return (
            <option key={index} value={index}>{kosten.naam}</option>
        )
    })

    const vogelsElements = vogels.map(vogel => {
        return (

            <div key={vogel.id} className="border rounded p-3 mb-2">
              <div className="row g-3">
                <div className="col-8">
                    <h5>{formatNumber(vogel.vogel.klasse)} - {vogel.vogel.kleurslag} <span className="badge bg-secondary">
                    {formatCurrency(vogel.totaal)}
                    </span></h5>
                </div>
                <div className="col-4">
                    <Button size="sm" className="float-end" variant="danger" onClick={(e) => handleDeleteVogel(vogel.id)}><IoTrashOutline size="1.5em" /></Button>
                    <Button size="sm" className="float-end me-3" onClick={(e) => handleDuplicateVogel(vogel.id)}><IoDuplicateOutline size="1.5em" /></Button>
                </div>
                <div className="col-2">
                  <Form.Group controlId={"formGridType"+vogel.id}>
                    <Form.Select aria-label="Selecteer type"
                      value={vogel.type}
                      onChange={(e) => handleChangeVogel(vogel.id, {'soort':kosten[e.target.value].naam, 'type': e.target.value})} >
                      {typeElements}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-2">
                  <Form.Group controlId={"formGridAantal"+vogel.id}>
                    <FormControl type="number" min="1" placeholder="Aantal" aria-label="Aantal" required
                      value={vogel.aantal}
                      onChange={(e) => handleChangeVogel(vogel.id, {'aantal': e.target.value})} />
                    <Form.Control.Feedback type="invalid">
                        Vul het aantal vogels in.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                {opties.velden.verzekeringvogel &&
                  <div className="col-4">
                    <Form.Group controlId={"formGridVerzekeringVogel"+vogel.id}>
                      <FormControl type="number" min="0.01" step="0.01"
                        placeholder={"Verzekering per "+(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" vogels" : "vogel")}
                        aria-label={"Verzekering per "+(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" vogels" : "vogel")}
                        required
                        value={vogel.verzekeringVogel}
                        onChange={(e) => handleChangeVogel(vogel.id, {'verzekeringVogel': e.target.value})} />
                      <Form.Control.Feedback type="invalid">
                        Vul het verzekeringsbedrag per {(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" vogels" : "vogel")} in.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                }
                {opties.velden.verzekeringkooi &&
                  <div className="col-4">
                    <Form.Group controlId={"formGridVerzkeringKooi"+vogel.id}>
                      <FormControl type="number" min="0.01" step="0.01"
                        placeholder={"Verzekering per "+(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" kooien" : "kooi")}
                        aria-label={"Verzekering voor per "+(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" kooien" : "kooi")}
                        required
                        value={vogel.verzekeringKooi}
                        onChange={(e) => handleChangeVogel(vogel.id, {'verzekeringKooi': e.target.value})} />
                      <Form.Control.Feedback type="invalid">
                        Vul het verzekeringsbedrag per {(kosten[vogel.type].aantal > 1 ? kosten[vogel.type].aantal+" kooien" : "kooi")} in.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                }
              </div>
            </div>
        )
    })

    return (
      <>

        <div className="row g-5">
          <div className="col-md-12">
            <Form noValidate className="needs-validation" validated={validated} onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-12">
                {vogelsElements}
                <hr className="my-4" />

                <div className="row g-3">
                  <div className="col-2">
                      <h5>Totaal:  <span className="badge bg-secondary">{formatCurrency(inschrijving.totaal)}</span></h5>
                  </div>
                  <div className="col-2">
                    <p>{inschrijving.aantal} inschrijvingen</p>
                    <p>{inschrijving.aantalVogels} vogels</p>
                  </div>
                  {opties.velden.verzekeringvogel &&
                    <div className="col-4 text-end">
                      <p>Verzekering vogels</p>
                      <p>{formatCurrency(inschrijving.verzekeringVogel)}</p>
                    </div>
                  }
                  {opties.velden.verzekeringkooi &&
                    <div className="col-4 text-end">
                      <p>Verzekering kooien</p>
                      <p>{formatCurrency(inschrijving.verzekeringKooi)}</p>
                    </div>
                  }
                </div>
                <hr className="my-4" />

                <Form.Control type="hidden" required value={tentoonstelling.vereniging.id} />

                <Button className="w-100" variant="primary" size="lg" type="submit" disabled={vogels.length === 0}>
                  Naar stap 3
                </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

      </>
    )
}
