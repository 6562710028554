import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function Prijzen( {inschrijving, setInschrijving, prijzen} ) {

    const prijzenElements = prijzen.map(prijs => {
        return (
          <div key={prijs} className="mb-3">
              <Form.Check
                type="radio"
                name="prijzen"
                required
                id={prijs}
                label={prijs}
                checked={inschrijving.prijs === prijs}
                onChange={(e) => setInschrijving({...inschrijving, prijs:e.target.id})}
              />
          </div>
        )
    })

    return (

      <>
        <h6>Prijzen</h6>

          {prijzen.length !== 0 && <Form.Group as={Row} className="mb-3" controlId="aantalDerby">
              <Form.Label column sm={{ span: 3, offset: 1 }}>
                Kies je prijs
              </Form.Label>
              <Col sm="8">
                {prijzenElements}
              </Col>
            </Form.Group>}
      </>

    )
}
