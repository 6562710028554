import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import TentoonstellingenList from './TentoonstellingenList'
import Stap from './Stap'
import Footer from './Footer'

import '../css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export const AppContext = React.createContext()

function App() {

  return (

    <Router>
        <div className="container">
          <main>
            <Switch>
              <Route exact path="/">
                <TentoonstellingenList />
              </Route>
              <Route path="/:slug" component={Stap} />
            </Switch>
          </main>

          <Footer />
        </div>
    </Router>

  )

}

export default App;
